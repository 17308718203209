import React, { Component } from "react";
import PropTypes from "prop-types";
import { GhostButton } from "ba-react/lib/components/GhostButton/GhostButton";
import ValidationField from "./ValidationField";
import classnames from "classnames";
import "./Form.scss";

const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/;
const isRequired = value => value.length > 0 || "This field is required";
const isEmail = value => !!value.match(emailRegex) || "Not a valid email";
const limitChar = value => value.length <= 240 || "Comment is too long.";
const inputs = [
	{
		key: "name",
		displayName: "Name",
		className: "form-input",
		validations: [isRequired],
		inputType: "input",
	},
	{
		key: "company",
		displayName: "Company",
		className: "form-input",
		validations: [isRequired],
		inputType: "input",
	},
	{
		key: "email",
		displayName: "Email",
		className: "form-input",
		validations: [isEmail],
		inputType: "input",
	},
	{
		key: "comments",
		displayName: "How can we help you?",
		className: "form-input textarea-content",
		validations: [isRequired, limitChar],
		inputType: "textarea",
	},
];

class Form extends Component {
	static propTypes = {
		active: PropTypes.bool,
		blur: PropTypes.bool,
		onSending: PropTypes.func,
		onSent: PropTypes.func,
	};

	static defaultProps = {
		blur: false,
		onSending: () => {},
		onSent: () => {},
	};

	state = {
		name: "",
		company: "",
		email: "",
		comments: "",
	};

	componentDidUpdate(prevProps) {
		if (prevProps.active && !this.props.active) {
			Array.from(document.getElementsByClassName("form-input")).forEach(input => input.blur());
		}
	}

	handleChange = key => event => {
		this.setState({ [key]: event.target.value });
	};

	handleSend = () => {
		this.props.onSending();
		fetch("https://l4mm6i8rle.execute-api.us-east-1.amazonaws.com/live/contact-form", {
			method: "POST",
			body: JSON.stringify({
				name: this.state.name,
				company: this.state.company,
				email: this.state.email,
				message: this.state.comments,
			}),
		}).then(() => this.props.onSent());
	};

	AllInputsValidated = () =>
		inputs.every(input =>
			input.validations.every(validation => typeof validation(this.state[input.key]) !== "string")
		);

	renderInputs = () =>
		inputs.map(input => (
			<ValidationField
				key={input.key}
				displayName={input.displayName}
				validations={input.validations}
				value={this.state[input.key]}
			>
				<input.inputType
					tabIndex={!this.props.active ? "-1" : undefined}
					className={input.className}
					value={this.state[input.key]}
					onChange={this.handleChange(input.key)}
					type={input.type}
				/>
			</ValidationField>
		));

	render() {
		const { blur } = this.props;
		const formClass = classnames("contact-form", { blur });
		return (
			<form className={formClass}>
				{this.renderInputs()}
				<div className="button-container">
					<GhostButton
						tabIndex={!this.props.active ? "-1" : undefined}
						submit={true}
						disabled={!this.AllInputsValidated()}
						onClick={this.handleSend}
						className="form-button"
					>
						SEND
					</GhostButton>
				</div>
			</form>
		);
	}
}

export default Form;
