import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./DynamicPage.scss";
import { textsHome as texts } from "textsHome";

class DynamicPage extends Component {
	static propTypes = {
		children: PropTypes.node,
		page: PropTypes.number,
		title: PropTypes.string,
		description: PropTypes.string,
		texts: PropTypes.arrayOf(
			PropTypes.shape({
				section: PropTypes.string,
				title: PropTypes.string,
				description: PropTypes.string,
			})
		),
		section: PropTypes.string,
		backgroundColor: PropTypes.string,
	};
	render() {
		const stylesToPage = classNames("dynamic-page", `dynamic-page-${this.props.page}`);
		return (
			<div className={stylesToPage} style={{ backgroundColor: this.props.backgroundColor }}>
				<h5 className="current-section">{texts[this.props.page].section}.</h5>
				{this.props.children}
			</div>
		);
	}
}

export default DynamicPage;
