import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import IconsBar from "../IconsBar/IconsBar";
import Arrow from "../Arrow/Arrow";

import "./FixedPage.scss";

class FixedPage extends Component {
	static propTypes = {
		page: PropTypes.number,
		onClickArrow: PropTypes.func,
	};

	static defaultProps = {
		onClickArrow: () => {},
	};
	render() {
		const stylesContainer = classNames("fixed-container", `fixed-container-${this.props.page}`);
		return (
			<div className={stylesContainer}>
				<div className="page-container">
					<span className="number-page">0{this.props.page + 1}.</span>
					<div className="page-line" />
				</div>
				<div className="bar-icons-container">
					<IconsBar />
				</div>
				<div className="arrow-line-container">
					<div className="vertical-line" />
					<Arrow onClick={this.props.onClickArrow} up={this.props.page === 4} />
				</div>
			</div>
		);
	}
}

export default FixedPage;
