import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { MaterialInput } from "ba-react/lib/components/MaterialInput/MaterialInput";

import "./ValidationField.scss";

class ValidationField extends Component {
	static propTypes = {
		validations: PropTypes.arrayOf(PropTypes.func),
		value: PropTypes.string,
		children: PropTypes.node.isRequired,
		displayName: PropTypes.string,
	};

	static defaultProps = {
		validations: [],
		value: "",
	};

	state = {
		showErrors: false,
	};

	startShowingErrors = () => {
		this.setState({ showErrors: true });
	};

	render() {
		const { validations, value, children, displayName } = this.props;

		const failedValidations = validations.filter(validation => validation(value) !== true);

		const isOk = !this.state.showErrors || failedValidations.length === 0;

		const inputClasses = classnames("validation-field", {
			ok: isOk,
			error: !isOk,
		});
		return (
			<React.Fragment>
				<MaterialInput
					className="material-input"
					label={displayName}
					titleSize={window.matchMedia("(max-width: 767px)").matches ? 2 : 1.2}
					placeholderSize={window.matchMedia("(max-width: 767px)").matches ? 3 : 1.8}
					units="rem"
				>
					{children &&
						React.cloneElement(children, {
							onBlur: event => {
								children.props.onBlur && children.props.onBlur(event);
								this.startShowingErrors();
							},
							className: inputClasses + (children.props.className ? ` ${children.props.className}` : ""),
						})}
				</MaterialInput>
				{isOk ||
					failedValidations.map((validation, key) => (
						<span key={key} className="validation-field-error">
							{validation(value)}
						</span>
					))}
			</React.Fragment>
		);
	}
}

export default ValidationField;
