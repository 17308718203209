import React, { Component } from "react";
import "./App.scss";
import PropTypes from "prop-types";
import Contact from "./Contact/Contact";
import DescriptionPage from "./DescriptionPage/DescriptionPage";
import DynamicPage from "./DynamicPage/DynamicPage";
import FixedPage from "./FixedPage/FixedPage";
import PageScroller from "Components/PageScroller/PageScroller";
import FixedBackground from "./FixedBackground/FixedBackground";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import { ScrollablePanel } from "ba-react/lib/components/ScrollablePanel/ScrollablePanel";
import nycImage from "assets/images/NY.jpg";
import bsasImage from "assets/images/buenos_aires.jpg";

const logoColor = ["#fff", "#67a5ba", "#fff", "#fff", "#67a5ba"];
class App extends Component {
	static propTypes = {
		page: PropTypes.number,
	};
	state = {
		page: 0,
		scrollTop: 0,
		regularScrollEnabled: false,
		alternateParity: false,
	};

	componentDidMount() {
		//preload images
		new Image().src = nycImage;
		new Image().src = bsasImage;
	}

	handlePageChange = page => {
		this.setState(state => ({
			page,
			regularScrollEnabled: page === 4,
			alternateParity:
				state.page !== page && state.page % 2 === page % 2 ? !state.alternateParity : state.alternateParity,
			scrollTop: 0,
		}));
	};

	handleClickArrow = () => {
		this.handlePageChange(this.state.page === 4 ? 0 : this.state.page + 1);
	};

	onScroll = scrollTop => {
		this.setState({ scrollTop });
	};

	handleScrollEnd = page => {
		if (page === 4) this.setState({ regularScrollEnabled: true });
	};

	goToPage = page => () => this.handlePageChange(page);

	render() {
		return (
			<ScrollablePanel
				onScroll={this.onScroll}
				scrollTop={this.state.scrollTop}
				disabled={!this.state.regularScrollEnabled}
				className="App"
			>
				<div className="intro-background" />
				<FixedBackground alternate={this.state.alternateParity} page={this.state.page} />
				<PageScroller
					page={this.state.page}
					animationTimer={700}
					transitionTimingFunction="ease-in-out"
					pageOnChange={this.handlePageChange}
					disabled={this.state.scrollTop > 0}
					regularScrollEnabled={this.state.regularScrollEnabled}
					timeBetweenScrolls={window.matchMedia("(max-width: 767px)").matches ? 0 : 400}
					onScrollEnd={this.handleScrollEnd}
				>
					<DynamicPage page={0}>
						<DescriptionPage page={0} />
					</DynamicPage>
					<DynamicPage page={1}>
						<DescriptionPage page={1} />
					</DynamicPage>
					<DynamicPage page={2}>
						<DescriptionPage page={2} />
					</DynamicPage>
					<DynamicPage page={3}>
						<DescriptionPage page={3} />
					</DynamicPage>
					<DynamicPage page={4}>
						<Contact active={this.state.page === 4} />
						<Footer />
					</DynamicPage>
				</PageScroller>
				<Header
					onClick={this.goToPage(0)}
					scrolled={this.state.scrollTop > 0}
					color={logoColor[this.state.page]}
				/>
				<FixedPage page={this.state.page} onClickArrow={this.handleClickArrow} />
			</ScrollablePanel>
		);
	}
}

export default App;
