import "react-app-polyfill/ie9"; // For IE 9-11 support
import "react-app-polyfill/ie11"; // For IE 11 support
import React from "react";
import ReactDOM from "react-dom";
import "index.scss";
import registerServiceWorker from "registerServiceWorker";
import { ThemeProvider } from "styled-components";
import theme from "styledComponentsTheme";
import App from "Components/App";
import Buggyfill from "viewport-units-buggyfill";

if (window.matchMedia("(orientation: portrait)").matches) Buggyfill.init();
if (navigator.userAgent.match(/Android/)) {
	setTimeout(function() {
		const viewheight = window.innerHeight;
		const viewwidth = window.innerWidth;
		const viewport = document.querySelector("meta[name=viewport]");
		viewport.setAttribute("content", "height=" + viewheight + "px, width=" + viewwidth + "px, initial-scale=1.0");
	}, 300);
}

registerServiceWorker();

const render = Component => {
	ReactDOM.render(
		<ThemeProvider theme={theme}>
			<Component />
		</ThemeProvider>,
		document.getElementById("root")
	);
};

render(App);

if (module.hot) {
	module.hot.accept("./Components/App", () => {
		const NextApp = require("./Components/App").default;
		render(NextApp);
	});
}
