import React, { Component } from "react";
import IconsBar from "../IconsBar/IconsBar";
import "./Footer.scss";
import Locations from "../Locations/Locations";

class Footer extends Component {
	state = {
		width: window.innerWidth,
	};
	updateDimensions = () => {
		this.setState({ width: window.innerWidth });
	};
	componentDidMount() {
		window.addEventListener("resize", this.updateDimensions);
	}
	componentWillUnmount() {
		window.removeEventListener("resize", this.updateDimensions);
	}

	render() {
		return (
			<footer className="footer-container">
				{window.matchMedia("(max-width: 767px)").matches && <Locations className="footer-locations" />}
				{window.matchMedia("(max-width: 767px)").matches && <IconsBar className="footer-icons" />}
				<div className="copyright">Copyright &copy; 2023 Blue Alba LLC. All rights reserved.</div>
			</footer>
		);
	}
}

export default Footer;
