import React from "react";
import PropTypes from "prop-types";
import "./PageScroller.scss";
import Buggyfill from "viewport-units-buggyfill";

const KEY_UP = 38;
const KEY_DOWN = 40;

export default class PageScroller extends React.Component {
	static propTypes = {
		children: PropTypes.node,
		page: PropTypes.number,
		animationTimer: PropTypes.number,
		transitionTimingFunction: PropTypes.string,
		pageOnChange: PropTypes.func,
		timeBetweenScrolls: PropTypes.number,
		disabled: PropTypes.bool,
		onScrollEnd: PropTypes.func,
		regularScrollEnabled: PropTypes.bool,
	};

	static defaultProps = {
		page: 0,
		animationTimer: 1000,
		transitionTimingFunction: "ease-in-out",
		pageOnChange: () => {},
		timeBetweenScrolls: 0,
		disabled: false,
		onScrollEnd: () => {},
		regularScrollEnabled: false,
	};

	state = {
		scrolling: false,
		previousTouchMove: null,
		jitter: false,
	};

	componentDidMount = () => {
		window.addEventListener("resize", this.onWindowResized);
		window.addEventListener("orientationchange", this.initBuggyfill);

		// Initial load timeout
		setTimeout(() => {
			document.ontouchmove = this.touchMove;
			document.ontouchend = this.touchEnd;
			document.onwheel = this.wheelScroll;
			document.onkeydown = this.keyPress;
			window.scrollTo(0, 1);
		}, 3200);
	};

	componentWillUnmount() {
		window.removeEventListener("resize", this.onWindowResized);
		window.removeEventListener("orientationchange", this.initBuggyfill);
		document.removeEventListener("ontouchmove", this.touchMove);
		document.removeEventListener("ontouchend", this.touchEnd);
		document.removeEventListener("onwheel", this.wheelScroll);
		document.removeEventListener("onkeydown", this.keyPress);
	}

	onWindowResized = () => {
		this.forceUpdate();
	};

	wheelScroll = event => {
		if (Math.abs(event.deltaY) > 0) {
			const scrollUp = event.deltaY < 0;
			this.scrollWindow(scrollUp);
		}
	};

	touchMove = event => {
		if (this.state.previousTouchMove) {
			const scrollUp = event.touches[0].clientY > this.state.previousTouchMove;
			this.scrollWindow(scrollUp);
		}
		this.setState({ previousTouchMove: event.touches[0].clientY });
	};

	touchEnd = () => {
		this.setState({ previousTouchMove: null });
	};

	keyPress = event => {
		if (event.keyCode === KEY_UP || event.keyCode === KEY_DOWN) {
			this.scrollWindow(event.keyCode === KEY_UP);
		}
	};

	canScrollToDirection = up => {
		return up
			? this.props.page > 0 && !this.state.scrolling
			: this.props.page < React.Children.count(this.props.children) - 1 && !this.state.scrolling;
	};

	scrollWindow = scrollUp => {
		if (!this.props.disabled && this.canScrollToDirection(scrollUp)) {
			this.props.pageOnChange(this.props.page + (scrollUp ? -1 : 1));
			this.setState({
				scrolling: true,
			});
		}
	};

	initBuggyfill = () => {
		if (window.matchMedia("(orientation: portrait)").matches) {
			Buggyfill.init();
		}
	};

	componentDidUpdate(prevProps) {
		if (prevProps.page !== this.props.page) {
			setTimeout(() => {
				this.props.onScrollEnd(this.props.page);
				this.setState({ scrolling: false, previousTouchMove: null, jitter: this.props.page === 4 });
			}, this.props.animationTimer + this.props.timeBetweenScrolls);
		}
	}

	scrollerRef = React.createRef();

	render() {
		const { animationTimer, transitionTimingFunction, page, children } = this.props;
		const pageRelativePosition = this.scrollerRef.current
			? -this.scrollerRef.current.getBoundingClientRect().height
			: -100;
		const offset = page * pageRelativePosition;

		return (
			<div ref={this.scrollerRef} className="page-scroller" tabIndex="-1" unselectable="on">
				<div
					style={{
						height: this.state.jitter ? "calc(100% + 1px)" : "100%",
						width: "100%",
						transition: `transform ${animationTimer}ms ${transitionTimingFunction}`,
						transform: `translate3d(0, ${offset}px, 0)`,
					}}
				>
					{children}
				</div>
			</div>
		);
	}
}
