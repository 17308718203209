import React, { Component } from "react";
import PropTypes from "prop-types";
import { textsHome } from "../../textsHome";

import "./DescriptionPage.scss";

class DescriptionPage extends Component {
	static propTypes = {
		page: PropTypes.number,
	};

	static defaultProps = {
		page: 0,
	};
	render() {
		const texts = textsHome;
		return (
			<div className="description-page" tabIndex="-1">
				<div className="titles-container" tabIndex="-1">
					<h1 className="main-title">
						<span className="main-text">{texts[this.props.page].title}</span>
					</h1>
					<div className="titles-divider" />
					<p className="subtext-container">
						<span className="subtext">{texts[this.props.page].description}</span>
					</p>
				</div>
			</div>
		);
	}
}

export default DescriptionPage;
