import React, { Component } from "react";
import "./Header.scss";
import PropTypes from "prop-types";
import Logo from "./Logo";
import classNames from "classnames";
import { Transition } from "react-transition-group";

class Header extends Component {
	static propTypes = {
		color: PropTypes.string,
		onClick: PropTypes.func,
		scrolled: PropTypes.bool,
	};

	static defaultProps = {
		onClick: () => {},
		scrolled: false,
	};
	render() {
		const { color, onClick, scrolled } = this.props;
		const styles = state => classNames("app-header", `app-header-${state}`, { scrolled });
		return (
			<Transition in appear timeout={1500}>
				{state => (
					<header className={styles(state)} tabIndex="-1">
						<Logo onClick={onClick} animationState={state} color={color} />
					</header>
				)}
			</Transition>
		);
	}
}

export default Header;
