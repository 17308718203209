export const textsHome = [
	{
		section: "Home",
		title: "Innovative Technology for the Financial  Industry",
		description: "We produce great looking applications focusing on the user experience.",
	},
	{
		section: "Who we are",
		title:
			"Blue Alba was founded to leverage our experience developing financial solutions offshore with a real agile approach",
		description:
			"We are a small software company that understands the buy-side business. We are a team of seasoned professionals with deep experience in developing user focused software solutions.",
	},
	{
		section: "What we do",
		title: "Blue Alba specializes in the delivery of end-to-end software solutions for the financial industry",
		description:
			"We build software fast yet reliably by seeking the minimum viable product at every iteration. We partner with our clients to ensure a fluent communication to satisfy their expectations.",
	},
	{
		section: "Why Blue Alba",
		title: "Blue Alba has a proven track record backed by client adoption and success",
		description: "We have provided a pathway for structural change and innovation with our clients.",
	},
	{
		section: "Contact",
	},
];
