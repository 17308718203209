import React, { Component } from "react";
import { GhostButton } from "ba-react/lib/components/GhostButton/GhostButton";
import { Spinner } from "ba-react/lib/components/Spinner/Spinner";
import Form from "./Form";
import PropTypes from "prop-types";
import "./FormContainer.scss";

const SendingStep = () => (
	<div className="sending-step">
		<Spinner className="spinner" />
		<span>Sending message</span>
	</div>
);

const SentStep = ({ onNewMessage }) => (
	<div className="sent-step">
		<div className="success-icon-container">
			<i className="icon-success" />
		</div>
		<span className="success-text">Thank you for contacting us</span>
		<GhostButton onClick={onNewMessage}>New message</GhostButton>
	</div>
);

SentStep.propTypes = {
	onNewMessage: PropTypes.func,
};

SentStep.defaultProps = {
	onNewMessage: () => {},
};

const steps = {
	write: "write",
	sending: "sending",
	sent: "sent",
};

class FormContainer extends Component {
	static propTypes = {
		active: PropTypes.bool,
	};

	state = {
		formKey: 0,
		step: steps.write,
	};

	onStep = step => () => {
		this.setState({ step });
	};

	onNewMessage = () => {
		this.setState(state => ({
			step: steps.write,
			formKey: state.formKey + 1,
		}));
	};

	render() {
		const { active } = this.props;
		const { step, formKey } = this.state;
		return (
			<div className="form-container">
				<Form
					active={active}
					key={formKey}
					blur={step !== steps.write}
					onSending={this.onStep(steps.sending)}
					onSent={this.onStep(steps.sent)}
				/>
				{step !== steps.write && (
					<div className="send-overlay">
						{step === steps.sending ? <SendingStep /> : <SentStep onNewMessage={this.onNewMessage} />}
					</div>
				)}
			</div>
		);
	}
}

export default FormContainer;
