import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import "./IconsBar.scss";
import "../../assets/fonts/fonts-img.scss";

class IconsBar extends Component {
	static propTypes = {
		className: PropTypes.string,
	};

	render() {
		return (
			<div className={classnames("icons-container", this.props.className)}>
				<div className="icon-container">
					<a
						className="icon-link"
						href="https://twitter.com/bluealballc"
						target="_blank"
						rel="noopener noreferrer"
						tabIndex="-1"
					>
						<span className="icon-img icon-twitter" />
					</a>
				</div>
				<div className="icon-container">
					<a
						className="icon-link"
						href="https://ar.linkedin.com/company/blue-alba"
						target="_blank"
						rel="noopener noreferrer"
						tabIndex="-1"
					>
						<span className="icon-img icon-linkedin" />
					</a>
				</div>
				<div className="icon-container">
					<a
						className="icon-link"
						href="https://github.com/bluealba"
						target="_blank"
						rel="noopener noreferrer"
						tabIndex="-1"
					>
						<span className="icon-img icon-github" />
					</a>
				</div>
				<div className="icon-container">
					<a className="icon-link" href="mailto:info@bluealba.com" rel="noopener noreferrer" tabIndex="-1">
						<span className="icon-img icon-email" />
					</a>
				</div>
			</div>
		);
	}
}

export default IconsBar;
