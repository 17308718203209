import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import "./Arrow.scss";

class Arrow extends Component {
	static propTypes = {
		onClick: PropTypes.func,
		up: PropTypes.bool,
	};

	static defaultProps = {
		onClick: () => {},
		up: false,
	};

	render() {
		const arrowClasses = classnames("icon-arrow", {
			inverted: this.props.up,
		});
		return (
			<div onClick={this.props.onClick} className="arrow-container">
				<div className={arrowClasses} />
			</div>
		);
	}
}

export default Arrow;
