import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Transition from "react-transition-group/Transition";

import "./FixedBackground.scss";

class FixedBackground extends Component {
	static propTypes = {
		page: PropTypes.number,
		alternate: PropTypes.bool,
	};

	state = {
		previousPage: this.props.page,
	};

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.page !== this.props.page) {
			this.setState({
				previousPage: prevProps.page,
			});
		}
	}

	render() {
		const styles = (active, background, page) =>
			classNames("background-container", {
				active,
				[`background-container-${page}`]: background,
			});
		return (
			<Transition in={this.props.page % 2 === (this.props.alternate ? 1 : 0)} timeout={300}>
				{state => (
					<React.Fragment>
						<div
							id="odd"
							className={styles(
								state === "exited" || state === "exiting",
								state !== "entered",
								state === "exited" || state === "exiting" ? this.props.page : this.state.previousPage
							)}
						/>
						<div
							id="even"
							className={styles(
								state === "entered" || state === "entering",
								state !== "exited",
								state === "entered" || state === "entering" ? this.props.page : this.state.previousPage
							)}
						/>
					</React.Fragment>
				)}
			</Transition>
		);
	}
}

export default FixedBackground;
