import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import "./Locations.scss";

class Locations extends Component {
	static propTypes = {
		className: PropTypes.string,
	};

	render() {
		return (
			<div className={classnames("locations-container", this.props.className)}>
				<p className="title-locations">Locations</p>
				<div className="address">
					<b>USA</b> 12 E 49th St.11th Floor, New York, NY 10017
				</div>
				<div className="address">
					<b>Argentina</b> Darwin 1154, Block C, 1F, CABA 1414
				</div>
			</div>
		);
	}
}

export default Locations;
