import React from "react";
import PropTypes from "prop-types";
import "./Logo.scss";
import classnames from "classnames";

const Logo = props => (
	<svg
		onClick={props.onClick}
		className={classnames(props.className, "logo", `logo-${props.animationState}`)}
		viewBox="0 -5 270 60"
	>
		<g id="Group_11" data-name="Group 11" transform="translate(-641.535 -409)">
			<g className={classnames("b-logo", `b-logo-${props.animationState}`)}>
				<path
					id="Path_21"
					data-name="Path 21"
					fill={props.color}
					d="M51.607 388.133h-9.64v32.36a17.541 17.541 0 0 0 5.619 12.678 17.292 17.292 0 0 0 12.771 5.083c2.811-.024 6.429-.362 7.692-3v3h10.073v-19.406a17.24 17.24 0 0 0-5.248-11.432 17.419 17.419 0 0 0-12.842-5.088 82.562 82.562 0 0 0-8.426 1.827v-16.022zm14.647 38.114a8.246 8.246 0 0 1-5.984 2.516 8.412 8.412 0 0 1-8.517-8.359l-.056-6.249a69.57 69.57 0 0 1 8.423-2.335 8.526 8.526 0 0 1 8.58 8.43 8.082 8.082 0 0 1-2.446 5.996z"
					transform="translate(599.568 20.867)"
				/>
			</g>
			<g
				id="Group_8"
				className={classnames("bluealba-word", `bluealba-word-${props.animationState}`)}
				data-name="Group 8"
				transform="translate(695.776 412)"
			>
				<path
					id="Path_10"
					data-name="Path 10"
					fill={props.color}
					d="M.085 584.8v-43.859H11.2q5.937 0 8.993 2.905t3.058 8.565a10.827 10.827 0 0 1-1.568 5.874 8.913 8.913 0 0 1-4.338 3.61 8.7 8.7 0 0 1 5.165 3.726 11.853 11.853 0 0 1 1.975 6.823q0 5.733-3.134 9.043t-8.884 3.313zm3.434-24.221H11.5a8.432 8.432 0 0 0 6.084-2.079q2.169-2.08 2.169-6.175 0-4.187-2.124-6.161t-6.429-1.969H3.519zm0 3.134v17.895h9.1a8.049 8.049 0 0 0 6.176-2.416 9.412 9.412 0 0 0 2.259-6.7 9.192 9.192 0 0 0-2.094-6.339 7.655 7.655 0 0 0-5.83-2.442z"
					transform="translate(-.085 -540.941)"
				/>
				<path
					id="Path_11"
					data-name="Path 11"
					fill={props.color}
					d="M30.358 584.8h-3.373v-43.859h3.373z"
					transform="translate(5.702 -540.941)"
				/>
				<path
					id="Path_12"
					data-name="Path 12"
					fill={props.color}
					d="M54.861 578.923q-2.772 4.491-8.585 4.489a7.774 7.774 0 0 1-6.688-3.072q-2.258-3.074-2.288-9.04v-21.085h3.314v21q0 4.429 1.566 6.718a5.142 5.142 0 0 0 4.519 2.29q6.327 0 8.1-6.145v-23.86h3.374v32.595h-3.25z"
					transform="translate(7.921 -538.946)"
				/>
				<path
					id="Path_13"
					data-name="Path 13"
					fill={props.color}
					d="M72.85 583.519a11.275 11.275 0 0 1-9.008-3.766q-3.254-3.765-3.314-10.724v-3.978q0-7.17 3.134-11.252a10.265 10.265 0 0 1 8.615-4.08q5.3 0 8.089 3.466t2.847 10.6v3.132h-19.37v1.838q0 5.6 2.4 8.646A8.106 8.106 0 0 0 73 580.448a9.728 9.728 0 0 0 7.8-3.525l1.747 2.23q-3.19 4.365-9.697 4.366zm-.574-30.668a7.179 7.179 0 0 0-6.07 2.786q-2.125 2.785-2.334 8.149h16v-.873q-.244-10.062-7.595-10.062z"
					transform="translate(12.918 -539.052)"
				/>
				<path
					id="Path_14"
					data-name="Path 14"
					fill={props.color}
					d="M117.667 572.543H101.22L97.485 584.8H93.9l13.978-43.864h3.193l13.978 43.864h-3.585zm-15.452-3.224h14.459l-7.23-23.528z"
					transform="translate(20.097 -540.941)"
				/>
				<path
					id="Path_15"
					data-name="Path 15"
					fill={props.color}
					d="M127.966 584.8h-3.373v-43.859h3.373z"
					transform="translate(26.7 -540.941)"
				/>
				<path
					id="Path_16"
					data-name="Path 16"
					fill={props.color}
					d="M157.244 570.044q0 7.532-2.636 11.447a8.841 8.841 0 0 1-7.847 3.918 9.126 9.126 0 0 1-8.527-4.97l-.151 4.367h-3.1v-43.864h3.314v15.818a8.952 8.952 0 0 1 8.406-5.152q5.271 0 7.862 3.72t2.682 11.1v3.614zM153.9 567q0-6.206-1.837-9.189t-6.025-2.981q-5.574 0-7.743 5.874v16.146a8.615 8.615 0 0 0 3.014 3.963 8.094 8.094 0 0 0 4.79 1.4 6.512 6.512 0 0 0 5.888-2.908q1.885-2.907 1.913-9.053V567z"
					transform="translate(28.934 -540.941)"
				/>
				<path
					id="Path_17"
					data-name="Path 17"
					fill={props.color}
					d="M177.135 582.917a21.023 21.023 0 0 1-.6-4.519 10.7 10.7 0 0 1-3.873 3.779 10.231 10.231 0 0 1-5.135 1.342 8.806 8.806 0 0 1-6.568-2.38 9.24 9.24 0 0 1-2.351-6.778 9.481 9.481 0 0 1 3.269-7.591q3.27-2.8 9.025-2.83h5.6v-4.551q0-3.494-1.732-5.016a7.473 7.473 0 0 0-5.076-1.521 7.271 7.271 0 0 0-5.09 1.807 6.024 6.024 0 0 0-1.989 4.67l-3.345-.029a8.91 8.91 0 0 1 3.012-6.793 10.71 10.71 0 0 1 7.563-2.787q4.91 0 7.441 2.47t2.53 7.2v16a22.074 22.074 0 0 0 .874 7.169v.361h-3.554zm-9.189-2.621a9.112 9.112 0 0 0 5.226-1.536 8.939 8.939 0 0 0 3.328-4.1v-7.923h-5.3q-4.582.06-6.9 2.033a6.624 6.624 0 0 0-2.32 5.319q.002 6.211 5.967 6.211z"
					transform="translate(34.017 -539.052)"
				/>
			</g>
		</g>
	</svg>
);

Logo.propTypes = {
	color: PropTypes.string,
	className: PropTypes.string,
	animationState: PropTypes.string,
	onClick: PropTypes.func,
};

Logo.defaultProps = {
	color: "#fff",
	onClick: () => {},
};
export default Logo;
