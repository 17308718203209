import React, { Component } from "react";
import PropTypes from "prop-types";
import FormContainer from "../Form/FormContainer";
import Locations from "../Locations/Locations";
import "./Contact.scss";

class Contact extends Component {
	static propTypes = {
		active: PropTypes.bool,
	};

	state = {
		width: window.innerWidth,
	};
	updateDimensions = () => {
		this.setState({ width: window.innerWidth });
	};
	componentDidMount() {
		window.addEventListener("resize", this.updateDimensions);
	}
	componentWillUnmount() {
		window.removeEventListener("resize", this.updateDimensions);
	}

	render() {
		return (
			<div className="contact-page-container">
				<div className="main-container">
					<div className="text-form">
						<p className="title">Get in touch</p>
						<p className="description">
							If you want to know more about our work and the way we partner up with our clients, please{" "}
							contact us by email at{" "}
							<a tabIndex={!this.props.active ? "-1" : undefined} href="mailto:info@bluealba.com">
								info@bluealba.com
							</a>{" "}
							or send us a message using this form.
						</p>
					</div>
					<FormContainer active={this.props.active} />
				</div>
				{window.matchMedia("(min-width: 768px)").matches && <Locations className="contact-locations" />}
			</div>
		);
	}
}

export default Contact;
